//
//
//
//

import { mapActions } from 'vuex';

export default {

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadCurrencies(),
      this.loadMethods(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('currencies', {
      loadCurrencies: 'getP2PCurrencies',
    }),
    ...mapActions('tradersPaymentMethods', {
      loadMethods: 'loadData',
    }),
  },
};
